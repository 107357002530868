.week-picker-panel{
    display: grid;
    grid-template-rows: auto 1fr;
    min-width: 203px;
}

.week-picker-panel-header{
    color: #000;
    font-size: 14px;
    height: 38px;
    line-height: 37px;
    margin-bottom: 5px;
    margin-top: 8px;
}
.week-picker-panel-body {
    left: 0;
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0 5px;
    position: absolute;
    right: 0;
    text-align: center;
}
.week-picker-panel-li {
    background-color: #0074d9;
    border-radius: 3px;
    box-shadow: 0 0 2px #8798ad;
    color: #fff;
    display: flex;
    margin: 4px 1px;
    position: relative;
}
.week-picker-panel-body-button {
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    font-family: Arial!important;
    font-size: 16px;
    height: 12px;
    justify-content: center;
    line-height: 5px;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 52%;
    transform: translateY(-50%) rotate(
45deg);
    width: 12px
}