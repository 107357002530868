@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

#video-player *:not(.vjs-zoom-duck__container):not(.vjs-zoom-duck__container *) {
    font-family: "VideoJS", sans-serif !important;
}

#video-player .vjs-zoom-duck__container,
#video-player .vjs-zoom-duck__container * {
    font-family: "Material Icons", sans-serif !important;
}

.video-js .vjs-zoom-icons {
	font-family: "Material Icons", sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 21px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-feature-settings: "liga";
}

.video-js .vjs-zoom-duck {
	font-family: "VideoJS", serif;
	cursor: pointer;
	font-weight: 400;
	font-style: normal;
}

.video-js .vjs-zoom-duck-7 .vjs-icon-placeholder:before {
	content: "\f114";
}

.video-js .vjs-zoom-duck-8 .vjs-icon-placeholder:before {
	content: "\f114";
}

.video-js .vjs-download-duck-7 .vjs-icon-placeholder:before {
    font-family: 'Material Icons' !important;
    content: 'center_focus_weak';
}

.video-js .vjs-download-duck-8 .vjs-icon-placeholder:before {
    font-family: 'Material Icons' !important;
    content: 'center_focus_weak';
}

.video-js .vjs-zoom-duck__container {
	right: 0;
	bottom: 30px;
	padding: 10px;
	visibility: hidden;
	position: absolute;
	opacity: 0;
	background-color: rgba(43, 51, 63, 0.7);
	transition: visibility 0.2s, opacity 0.2s;
}

.video-js .vjs-zoom-duck__container.open {
	display: flex;
	opacity: 1;
	visibility: visible;
	flex-direction: column;
}

.video-js .vjs-zoom-duck__space {
	width: 22px;
	height: 22px;
	display: inline-block;
}

.video-js .vjs-zoom-duck__button {
	cursor: pointer;
	border: none;
	width: 22px;
	height: 22px;
	border-radius: 100%;
    padding: 1px;
}

.video-js .vjs-zoom-duck__button:hover {
	color: rgba(43, 51, 63, 0.7);
	background-color: white;
}
