.location-direction-col {
    padding-left: 16px !important;
}

@media (max-width: 1200px) {
    .location-direction-col {
        padding-left: 8px !important;
    }
}

.location-direction-chart {
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    .location-indicator-div {
        position: absolute;
        background: #6b439d;
        color: #fff;
        padding: 10px;
        text-align: center;
        border-radius: 6px;

        .location-name {
            font-weight: 600;
            font-size: 8px;
            line-height: 109%;
            margin: 0 0 5px 0;
        }

        .count {
            font-weight: 600;
            font-size: 22px;
            line-height: 109%;
            color: #fff;
            margin: 0;
        }
    }

    // .loc-1 {
    //     top: 19%;
    //     left: 7%;
    // }

    // .loc-2 {
    //     top: 10px;
    //     left: 45%;
    // }

    // .loc-3 {
    //     top: 10px;
    //     left: 62%;
    // }

    // .loc-4 {
    //     top: 34%;
    //     right: 8%;
    // }

    // .loc-5 {
    //     top: 50%;
    //     right: 5%;
    // }

    // .loc-6 {
    //     top: 66%;
    //     right: 28%;
    // }

    // .loc-7 {
    //     top: 50%;
    //     left: 8%;
    // }

    // .loc-8 {
    //     bottom: 11%;
    //     left: 8%;
    // }
}

@media (min-width: 1201px) and (max-width: 1440px) {
    .location-direction-chart {
        .location-indicator-div {
            .count {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: 801px) and (max-width: 992px) {
    .location-direction-chart {
        .location-indicator-div {
            .count {
                font-size: 20px !important;
            }
        }
    }
}

@media (max-width: 801px) {
    .location-direction-chart {
        .location-indicator-div {
            .count {
                font-size: 15px !important;
            }
        }
    }
}

.location-direction-chart .loc-12 {
    bottom: 32%;
    left: 3%;
}
.location-direction-chart .loc-11 {
    top: 3%;
    left: 53%;
}
.location-direction-chart .loc-10 {
    bottom: 8%;
    right: 2%;
}
.location-direction-chart .loc-9 {
    bottom: 0%;
    right: 23%;
}
.location-direction-chart .loc-8 {
    top: 60%;
    right: 0%;
}
.location-direction-chart .loc-7 {
    top: 42%;
    left: 3%;
}
.location-direction-chart .loc-6 {
    top: 66%;
    right: 28%;
}
.location-direction-chart .loc-5 {
    top: 43%;
    right: 0%;
}
.location-direction-chart .loc-4 {
    top: 26%;
    right: 8%;
}
.location-direction-chart .loc-3 {
    top: 8%;
    left: 75%;
}
.location-direction-chart .loc-2 {
    top: 10px;
    left: 22%;
}
.location-direction-chart .loc-1 {
    top: 21%;
    left: 7%;
}
