$dividerColor: #E5E5E5;

.report-top-divider {
    height: 4px;
    background: $dividerColor;
    margin: 10px 0;
    color: $dividerColor;
}

.card-section-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    color: $primaryColor;
    margin: 10px;
}

card-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    // background: $iconBgColor;
    .icon-element {
        flex: 1;
        color: $primaryColor;
    }

}
.card-value{
    text-align: center;
    flex:1;
    font-weight: 600;
    font-size: 2.7vw;
    line-height: 39px;
    color: $primaryColor;
}

.card-text {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 20px;
    color: $primaryColor;
    text-align: center;
    margin-top: 12px;
}

@media only screen and (max-width: 800px) {
    .card-value{
        font-size: 30px
    }

    .card-text {
        font-size: 20px;
    }
}  

@media only screen and (max-width: 1024px) {
    .card-value{
        font-size: 30px
    }

    .card-text {
        font-size: 20px;
    }
}  

.graph-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.card-data-unavailable{
    color: red;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    margin-top: 12px;
}

.reports-message-outer-box{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    .reports-message-inner-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .reports-message-header{
            font-size: 14px;
            font-weight: 600px;
            color: #7A7A7A;
            margin-top: -10px;
            padding-left: 20px;
        }
        .report-message-upper-content{
            font-size: 10px;
            font-weight: 600px;
            color: #7A7A7A;
            margin-top: 5px;
        }
        .report-message-lower-content{
            font-size: 10px;
            font-weight: 600px;
            color: #7A7A7A;
            margin-top: -1px;
        }
    }
}





.report-message-content{
    font-size: 10px;
    font-weight: 600px;
    color: "#7A7A7A";
    margin-top: 5px;
}
