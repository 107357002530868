.stats-container {
    display: flex;
    gap: 1.5%;
    align-content: space-between;

    .stat-card {
        background-color: $whiteColor;
        padding: 15px 23px 10px;
        border-radius: 10px;
        text-align: left;
        color: $primaryColor;
        width: 33%;

        .title-wrapper {
            display: block;
            min-height: 35px;

            h4 {
                font-size: 14px;
                line-height: 133%;
                font-weight: 600;
                margin-top: 0;
            }
        }

        .figure-wrapper {
            display: flex;
            align-items: center;

            .Icon-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 43px;
                height: 43px;
                background: $primaryBgColor;
                border-radius: 50%;
                font-size: 25px;
                margin-right: 22px;
            }

            // .count {
            //     line-height: 67.5px;
            //     color: $primaryColor;
            //     font-size: 45px;
            //     font-weight: 600;
            // }
        }
    }
}

.stat-card {
    background-color: $whiteColor;
    padding: 15px 23px 10px;
    border-radius: 10px;
    text-align: left;
    color: $primaryColor;
    width: 100%;

    .title-wrapper {
        display: block;
        min-height: 35px;

        h4 {
            font-size: 14px;
            line-height: 133%;
            font-weight: 600;
            margin-top: 0;
        }
    }

    .figure-wrapper {
        display: flex;
        align-items: center;

        .Icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 43px;
            height: 43px;
            background: $primaryBgColor;
            border-radius: 50%;
            font-size: 25px;
            margin-right: 22px;
        }

        // .count {
        //     line-height: 67.5px;
        //     color: $primaryColor;
        //     font-size: 45px;
        //     font-weight: 600;
        // }
    }
}

.stat-card-width-40 {
    width: 40% !important;
}
.stat-card-width-50 {
    width: 50% !important;
}
.stat-card-wrapper-width {
    display: flex;
    gap: 3%;
    width: 70% !important;
}
.stat-card-flex-col {
    display: flex!important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: space-between !important;
}
.icon-background-none {
    background: none !important;
}
.count-border {
    border: 3px solid $primaryColor !important;
    padding: 0px 5%;
}
.text-field-width {
    width: 50%;
}
.loading-wrapper {
    width: 100% !important;
}
.count {
    font-weight: 600;
    font-size: 45px;
    line-height: 67.5px;
    color: $primaryColor;
}

@media only screen and (max-width: 1024px) {
    .count {
        font-size: 35px !important;
    }
    .stat-card {
        padding: 15px 10px !important;
    }
    .title-wrapper {
        padding: 0 0 0 5px !important;
        min-height: 64px !important;
    }
}

// region stats card
.region-stat-card-wrapper {
    background: #eaeef6;
    border-radius: 10px;
    display: flex;
    min-height: 130px;
    margin: 0 0 30px 0 !important;
}

.region-stat-innaer-wrapper {
    display: flex;
    justify-content: flex-start;
    margin: 0 30px 0px 0px !important;
    border-right: 1px solid #e0e0e0;
}

.region-state-row {
    display: flex;
    align-items: flex-start;
    border-right: 1px solid #ddd;
    padding: 0 35px 0 0;

    .Icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        background: $primaryBgColor;
        border-radius: 50%;
        font-size: 25px;
        margin-right: 22px;

        svg {
            color: $activeColor;
        }
    }

    .region-state-header {
        color: $activeColor;
        font-size: 26px;
        font-weight: 600;
    }
}

.region-stat-innaer-wrapper_right {
    display: flex;
    justify-content: flex-start;
    flex-direction: column !important;
    margin: 10px 0 0 0 !important;
}

@media (max-width: 899px) {
    .region-stat-innaer-wrapper_right {
        margin-left: 60px !important;
    }
}

.region-state-row-left {
    display: flex;
    align-items: center;
    color: $primaryColor;
    font-size: 14px;
    margin: 0 0 5px 0 !important;
    font-weight: 600;
    svg {
        margin: 0 10px 0 0;
    }
}
//-----
.region-stat-card-wrapper {
    background: #eaeef6;
    border-radius: 10px;
    // padding: 15px;
    display: flex;
    min-height: 130px;
    margin: 0 0 30px 0 !important;
    cursor: pointer;

    .right-content {
        align-content: flex-end;
        padding: 0;
        margin: 0;
        border-radius: 0 10px 10px 0;
        text-align: center;
    }
    .txtWrapper {
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        display: none;
        height: 100%;

        .lblOne {
            font-size: 14px;
            font-weight: 400;
        }
        .lblTwo {
            font-size: 16px;
            font-weight: 600;
        }
    }
    .right-content-data-red {
        background-color: #eaeef6;
        border-right: 8px solid $darkDangerColor;
    }
    .right-content-data-yellow {
        border-right: 8px solid #f48c21;
        background-color: #eaeef6;
    }
}

.region-stat-card-wrapper:hover {
    .right-content {
        color: $whiteColor;
    }
    .txtWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        height: 100%;

        .lblOne {
            font-size: 14px;
            font-weight: 400;
        }
        .lblTwo {
            font-size: 16px;
            font-weight: 600;
        }
    }
    .right-content-data-red {
        border-right: 8px solid $darkDangerColor;
        background-color: $darkDangerColor !important;
    }
    .right-content-data-yellow {
        border-right: 8px solid #f48c21;
        background-color: #f48c21;
    }
}
//--------

// region stats - LOCATION card
.region-statlocation-card-wrapper {
    background: #eaeef6;
    border-radius: 10px;
    // border-right: 8px solid $darkDangerColor;
    border-right: 1px solid #eaeef6;
    display: flex;
    min-height: 130px;
    margin: 0 0 30px 0 !important;
    flex-direction: row !important;
    cursor: pointer;

    .right-content {
        align-content: flex-end;
        padding: 0;
        margin: 0;
        border-radius: 0 10px 10px 0;
        text-align: center;
    }
    .txtWrapper {
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        display: none;
        height: 100%;

        .lblOne {
            font-size: 14px;
            font-weight: 400;
        }
        .lblTwo {
            font-size: 16px;
            font-weight: 600;
        }
    }
    .right-content-data-red {
        background-color: #eaeef6;
        border-right: 8px solid $darkDangerColor;
    }
    .right-content-data-yellow {
        border-right: 8px solid #f48c21;
        background-color: #eaeef6;
    }
}

.region-statlocation-card-wrapper:hover {
    .right-content {
        color: $whiteColor;
    }
    .txtWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        height: 100%;

        .lblOne {
            font-size: 14px;
            font-weight: 400;
        }
        .lblTwo {
            font-size: 16px;
            font-weight: 600;
        }
    }
    .right-content-data-red {
        border-right: 8px solid $darkDangerColor;
        background-color: $darkDangerColor !important;
    }
    .right-content-data-yellow {
        border-right: 8px solid #f48c21;
        background-color: #f48c21;
    }
}

.region-statlocation-card-Innerwrapper {
    padding: 15px;
}

.region-statlocation-innaer-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 0 0px 10px 0;
}

.region-statlocation-row {
    display: flex;
    align-items: center;
    padding: 0 35px 0 0;

    .Icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        background: $primaryBgColor;
        border-radius: 50%;
        font-size: 25px;
        margin-right: 22px;

        svg {
            color: $activeColor;
        }
    }
    .LocationIcon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        background: $locationIconWrapper;
        border-radius: 50%;
        font-size: 16px;
        margin-right: 17px;
        svg {
            color: $activeColor;
            font-size: 20px;
        }
    }

    .region-statlocation-header {
        color: $activeColor;
        font-size: 26px;
        font-weight: 600;
    }
}

.region-statlocation-innaer-wrapper_bottom {
    display: flex;
    justify-content: flex-start;
    flex-direction: row !important;
    padding: 0 0 0 38px !important;
}

.region-statlocation-row-left {
    display: flex;
    align-items: flex-start;
    color: #21315b;
    font-size: 14px;
    margin: 0 25px 5px 25px !important;
    // padding: 0 35px 0 10px;
    border-right: 1px solid #ccc;
    flex-direction: column !important;
    font-weight: 600;

    .region-statlocation-row-subrow {
        display: flex;
    }
    svg {
        margin: 0 6px 0 0;
    }
    .lgaData {
        color: #7a7a7a !important;
        font-weight: 400;
    }
}
.region-statlocation-row-right {
    display: flex;
    color: #21315b;
    font-size: 14px;
    margin: 0 0 5px 0 !important;
    padding: 0 10px 0 0px;
    font-weight: 600;
    .region-statlocation-row-subrow {
        display: flex;
    }
    .region-statlocation-row-subrow-data {
        display: flex;
        font-weight: 400;
        margin: 0 0 0 5px;
    }
    svg {
        margin: 0 6px 0 0;
    }
}
.StarIcon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    margin-right: 22px;
    svg {
        font-size: 14px;
    }
    .color-yellow {
        color: #f4b921 !important;
    }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .mobilemargin {
        margin: 0 0 0 15px !important;
    }
    .region-statlocation-row-left {
        margin: 0 25px 15px 0px !important;
        border: none !important;
    }
    .region-statlocation-card-wrapper {
        .right-content {
            display: flex;
        }
        .txtWrapper {
            display: none;
        }
        .right-content-data-red {
            background-color: #eaeef6 !important;
            border-right: 8px solid $darkDangerColor !important;
        }
        .right-content-data-yellow {
            border-right: 8px solid #f4b921 !important;
            background-color: #eaeef6 !important;
        }
    }
    .region-statlocation-card-wrapper:hover {
        .right-content {
            display: flex;
        }
        .txtWrapper {
            display: none;
        }
        .right-content-data-red {
            background-color: #eaeef6 !important;
            border-right: 8px solid #eaeef6 !important;
        }
        .right-content-data-yellow {
            border-right: 8px solid #eaeef6 !important;
            background-color: #eaeef6 !important;
        }
    }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 1024px) {
    .region-statlocation-row-left {
        margin: 0 25px 15px 0px !important;
        border: none !important;
    }
    .region-statlocation-card-wrapper {
        .right-content {
            display: flex;
        }
        .txtWrapper {
            display: none;
        }
        .right-content-data-red {
            background-color: #eaeef6 !important;
            border-right: 8px solid $darkDangerColor !important;
        }
        .right-content-data-yellow {
            border-right: 8px solid #f4b921 !important;
            background-color: #eaeef6 !important;
        }
    }
    .region-statlocation-card-wrapper:hover {
        .right-content {
            display: flex;
        }
        .txtWrapper {
            display: none;
        }
        .right-content-data-red {
            background-color: #eaeef6 !important;
            border-right: 8px solid #eaeef6 !important;
        }
        .right-content-data-yellow {
            border-right: 8px solid #eaeef6 !important;
            background-color: #eaeef6 !important;
        }
    }
}

.fw700 a {
    text-decoration: none;
}

.group-btn {
    text-decoration: none;
}
.circle-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23.87%;
}

.donut-card-wrapper {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    flex-direction: column !important;
    width: 23.87%;

    .donut-title-wrapper {
        min-height: 63px;
        display: flex;
        flex-direction: row;

        .donut-Icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 43px;
            height: 43px;
            background: #f9f9fa;
            border-radius: 20px;
            svg {
                color: #21315b;
                width: 43px;
                font-size: 26px;
            }
        }
        .donut-red-Icon-wrapper {
            svg {
                color: #c62034 !important;
                font-size: 23px;
            }
        }
        h4 {
            font-size: 14px;
            line-height: 133%;
            font-weight: 600;
            margin: 5px 0 0 16px;
            color: #21315b;
        }
    }

    .donut-figure-wrapper {
        position: relative;
        width: 100%;
        height: 120px;

        .chart-wrapper {
            position: absolute;
            width: 120px;
            height: 120px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 992px) {
    .donut-card-wrapper {
        width: 49.25%;
        margin-bottom: 1.5% !important;
    }
}

@media (min-width: 992px) and (max-width: 1150px) {
    .donut-card-wrapper {
        width: 32.3%;
        margin-bottom: 1.5% !important;
    }
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 16px !important;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
}

.card-header-main-color {
    background-color: $activeColor;
    border-bottom: 1px solid $activeColor;
}

.card-header-black-color {
    background-color: #21315b;
    border-bottom: 1px solid #21315b;
}

.data-row {
    display: flex;
    align-items: center;
    padding: 0;
}

.data-card-key {
    color: #21315b;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
}

.data-card-value {
    color: #21315b;
    font-size: 14px;
    margin: 0;
    overflow-wrap: anywhere;
}

.card-inner .css-ofhni-MuiGrid-root {
    padding: 0;
    border-bottom: 1px solid #eee;
    height: 48px;

    &:last-child {
        border-bottom: 0;
    }
}

.icon-right {
    position: absolute !important;
    right: 12px;
    top: 12px;
}
