.MuiDialogContent-root {
    label {
        color: #21315b;
        font-weight: 600;
        font-size: 14px;
    }
}

.MuiTypography-h6 {
    font-size: 26px !important;
    font-weight: 600 !important;
    color: #21315b !important;
}

.dialogBTN-wrapper-filld {
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        background: #6b439d;
        font-size: 14px;
        line-height: 21px;
        border-radius: 20px;
        border-color: #6b439d;
        height: 35px;
        color: #fff;
        border: 1px solid;
        padding: 5px 25px;
    }
}
.dialogBTN-wrapper-filld {
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        background: #6b439d;
        font-size: 14px !important;
        line-height: 21px;
        border-radius: 20px;
        border-color: #6b439d;
        height: 35px;
        color: #fff;
        border: 1px solid;
        font-weight: 400 !important;
        text-transform: unset !important;
    }
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
        background: #6b439d;
        font-size: 14px !important;
        line-height: 21px;
        border-radius: 20px;
        border-color: #6b439d;
        height: 35px;
        color: #fff;
        border: 1px solid;
        font-weight: 400 !important;
        text-transform: unset !important;
    }
}

.dialogBTN-wrapper-nonfilld {
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
        background: #fff;
        font-size: 14px !important;
        line-height: 21px;
        border-radius: 20px;
        border-color: #6b439d;
        height: 35px;
        color: #6b439d;
        border: 1px solid;
        font-weight: 400 !important;
        text-transform: unset !important;
        padding: 5px 25px;
    }
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
        background: #fff;
        font-size: 14px !important;
        line-height: 21px;
        border-radius: 20px;
        border-color: #6b439d;
        height: 35px;
        color: #6b439d;
        border: 1px solid;
        font-weight: 400 !important;
        text-transform: unset !important;
    }
}

.MuiDialogContent-root {
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 30px !important;
        padding: 0 14px 0 10px !important;
        min-width: 265px !important;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 12px !important;
        font-size: 14px !important;
        color: #7a7a7a !important;
    }
}
.css-hlj6pa-MuiDialogActions-root {
    border-top: 1px solid #e0e0e0 !important;
}

.autocomplete-dropdown {
    width: 500px;
}

.full-screen-dialog {
    padding: 15px;
}

.dialog-footers {
    padding: 12px !important;
}

.full-width-dialog-wrapper {
    .MuiDialog-paperFullScreen {
        padding: 30px;

        canvas {
            max-height: calc(100vh - 60px) !important;
        }
    }
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    color: #fff !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 13px !important;
}
