.select-dropdown {
    display: block;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    font-size: 14px;
    line-height: 21px;
    height: 35px;
    color: $secondaryColor;
    border: 1px solid $borderColor;
    border-radius: 20px;
    padding: 7px 35px 7px 18px;
    width: auto;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
        #fff no-repeat calc(100% - 5px) !important;

    &:focus {
        box-shadow: none;
        outline: 0;
    }
}
.width100 {
    width: 100%;
}

.width140 {
    min-width: 140px;
}

.width145 {
    min-width: 150px;
}

.width265 {
    min-width: 265px;
}

.width400 {
    min-width: 350px;
}

.width200 {
    min-width: 200px;
}

.long-dropdown {
    display: block;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    font-size: 14px;
    line-height: 21px;
    height: 35px;
    color: $secondaryColor;
    border: 1px solid $borderColor;
    border-radius: 20px;
    padding: 7px 35px 7px 18px;
    width: auto;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
        #fff no-repeat calc(100% - 5px) !important;

    &:focus {
        box-shadow: none;
        outline: 0;
    }
}
@media only screen and (max-width: 1440px) {
    .select-dropdown {
        padding: 7px 26px 7px 14px !important;
    }
}
@media only screen and (max-width: 1440px) {
    .long-dropdown {
        padding: 7px 26px 7px 14px !important;
        min-width: 210px;
    }
    .width140 {
        min-width: 140px !important;
    }
    .width164 {
        min-width: 230px !important;
    }
    .width300 {
        min-width: 240px !important;
    }

    .width200 {
        min-width: 200px !important;
    }
}
