.sidebar {
    position: fixed;
    top: 60px;
    height: 100%;
    width: 65px;
    background: $whiteColor;
    transition: all 0.5s ease;
    font-size: 14px;
    line-height: 21px;
    height: calc(100vh - 55px);
    padding-bottom: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;

    .menu-btn-wrapper {
        position: absolute;
        left: 50%;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        font-size: 18px;
        transform: translateX(-50%);
        border-radius: 50%;
        color: $primaryColor;
        background: $iconBthBgColor;
        cursor: pointer;
    }

    .search-input-wrapper {
        margin: 70px 11px 0 11px;

        .search-icon {
            left: 13px;

            &:hover {
                color: $activeColor;
                cursor: pointer;
                font-size: 22px;
            }
        }

        .search-input {
            border: 0;
        }
    }

    .filled-btn {
        height: 35px;
        padding: 8px;
        width: 34px !important;
    }

    .menu-tree {
        overflow: hidden;
        margin-top: 25px;
        padding-bottom: 100px;

        .expandedIcon {
            top: 10px;
            right: 17px;
            position: absolute;
            color: $primaryColor;
            font-size: 14px;
        }

        .favourite-active-icon {
            top: 8px;
            right: 15px;
            position: absolute;
            font-size: 18px;
            color: $yellowColor;
        }

        .favourite-inactive-icon {
            top: 8px;
            right: 15px;
            position: absolute;
            font-size: 18px;
            color: $borderColor;
        }

        .MuiTreeItem-iconContainer {
            margin-right: 5px !important;
        }

        .level1 {
            .MuiTreeItem-content {
                padding-left: 25px;
            }
        }

        .level2 {
            .MuiTreeItem-content {
                padding-left: 34px;
            }
        }

        .level3 {
            .MuiTreeItem-content {
                padding-left: 51px;
            }
        }

        .level4 {
            .MuiTreeItem-content {
                padding-left: 68px;
            }
        }

        .level5 {
            .MuiTreeItem-content {
                padding-left: 85px;
            }
        }
    }

    .create-new-group-wrapper {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 20px 23px;
        width: 65px;
        .filled-btn {
            width: 100%;
        }
    }
}

.sidebar.active {
    width: 300px;

    .menu-btn-wrapper {
        left: 99%;
        top: 25px;
    }

    .menu-tree {
        margin-top: 68px;
        .MuiTreeItem-iconContainer {
            margin-right: -5px !important;
        }
    }

    .search-input-wrapper {
        margin: 25px 50px 0 15px;

        .search-input {
            border: 1px solid $borderColor;
        }
    }

    .create-new-group-wrapper {
        padding:  7px 20px 10px 20px;
        width: 300px;
        background-color: #fff;
        .filled-btn {
            height: 35px;
            padding: unset;
            width: 100% !important;
            white-space: nowrap;
        }
    }
}

.sidebar.active ~ .content {
    width: calc(100% - 300px);
    left: 300px;
    box-sizing: border-box;
}

@media (max-width: 767px) {
    .sidebar.active ~ .content {
        width: 100%;
        left: unset;
    }

    // .create-new-group-wrapper {
    //     width: 65px;
    // }
}
.sidebar-top-header{
    background-color: white;
    z-index: 3;
    margin-top: -4px;
    padding-left: 14px;
    width: 300px;
    height: 64px;
    position: fixed;
}
