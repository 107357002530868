.go-to-summery-link {
    display: flex;
    font-size: 14px;
    line-height: 133%;
    text-decoration-line: underline;
    color: #21315b;
    margin-bottom: 30px;
    align-items: center;

    &:hover {
        color: $activeColor;
    }

    .icon {
        background: $iconBthBgColor;
        border-radius: 50%;
        padding: 3px;
        font-size: 22px;
        margin-left: 10px;
    }
}

.event-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 17px;

    .icon {
        align-items: center;
        display: flex;
        width: 65px;

        .event-icon {
            box-sizing: border-box;
            font-size: 40px;
            background: $primaryBgColor;
            padding: 7px;
            border-radius: 50%;
        }
    }

    .event-content {
        p {
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
        }
    }
}
