.filled-btn {
    background: $activeColor;
    font-size: 14px;
    line-height: 21px;
    border-radius: 20px;
    border-color: $activeColor;
    height: 35px;
    color: #fff;
    border: 1px solid;

    &:hover {
        background: $activeColor;
        cursor: pointer;
    }

    &:focus {
        background: $activeColor;
        box-shadow: none;
    }
}

.nonfilled-btn {
    background: $whiteColor;
    font-size: 14px;
    line-height: 21px;
    border-radius: 20px;
    border-color: $activeColor;
    height: 33px;
    color: $activeColor;
    border: 1px solid;

    &:hover {
        background: $whiteColor;
        cursor: pointer;
    }

    &:focus {
        background: $whiteColor;
        box-shadow: none;
    }
}

.filter-action-btn-wrapper {
    display: flex;
    align-items: end;
    gap: 8px;
}

.filterBtn {
    padding: 0 18px;
    min-width: 112px;
    z-index: 1;
}
.filterBtn-reporting {
    padding: 0 18px;
    min-width: 85px;
}
@media only screen and (max-width: 1440px) {
    .filterBtn {
        padding: 0 7px !important;
        min-width: 112px;
    }
}

.filterButtonLeftMargin {
    margin-left: 20px;
    padding: 0 18px;
    min-width: 85px;
}

.filterBtnNoMargin {
    min-width: 85px;
    padding: 0 18px;
}

.expand-btn {
    position: absolute !important;
    top: 15px;
    right: 15px;
    background-color: #f2f2f2 !important;
    color: #7a7a7a !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.download-btn {
    cursor: pointer;
    margin-left: "1%";
    background-color: #f2f2f2 !important;
    color: #7a7a7a !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}

.group-btn {
    color: #fff !important;
}

.expand-btn-sub {
    background-color: #f2f2f2 !important;
    color: #7a7a7a !important;
    font-size: 14px !important;
    border-radius: 5px !important;
}
