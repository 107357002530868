.common-table {
    color: $primaryColor;
    font-size: 14px;
    line-height: 133%;

    thead {
        border-style: none;

        tr {
            th {
                font-weight: 600;
                font-size: 14px;
                line-height: 133%;
                color: $primaryColor;
                padding: 16px 0px;
                border-bottom: 1px solid $borderColor !important;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 16px 0px;
            }
        }
    }

    .link {
        color: $activeColor;
    }
}

.MuiDataGrid-root {
    border: 1px solid #fff !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    color: $primaryColor !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}
.MuiDataGrid-root .MuiDataGrid-cell {
    color: $primaryColor !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}
.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    color: $whiteColor;
    display: none !important;
}

.css-1iwtne7-MuiTypography-root-MuiLink-root {
    color: #6b439d !important;
    text-decoration-color: #6b439d !important;
}

.css-okt5j6-MuiDataGrid-columnHeaders {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}
