$primaryColor: #21315b;
$secondaryColor: #7a7a7a;
$activeColor: #6b439d;
$linkHoverBgColor: #f3f0f7;
$primaryBgColor: #f8f9fa;
$borderColor: #e0e0e0;
$iconBgColor: #f4f4f4;
$iconBthBgColor: #ebeff8;
$BgColor: #f4f4f4;
$whiteColor: #fff;
$dangerColor: #fe102d;
$darkDangerColor: #c62034;
$yellowColor: #f4b921;
$locationIconWrapper: #e7e9f4;
