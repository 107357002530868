@import "../abstracts/variables";

.main-navbar {
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: $whiteColor;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    z-index: 3;
    box-sizing: border-box;

    .navbar-brand {
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        text-decoration: none;
        white-space: nowrap;

        img {
            margin-left: 20px;
        }
    }

    .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-nav {
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;

        .non-mobile {
            display: flex;
        }

        @media only screen and (max-width: 730px) {
            .non-mobile {
                display: none;
            }
        }

        .link {
            display: block;
            font-size: 14px;
            line-height: 21px;
            padding: 4.5px 20px;
            color: $secondaryColor;
            text-decoration: none;
            transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out;
            margin-left: 3px;
            margin-right: 3px;

            &:hover {
                color: $activeColor;
            }
        }

        @media only screen and (max-width: 992px) {
            .link {
                display: block;
                padding: 4.5px 12px !important;
            }
        }

        .active {
            background: $linkHoverBgColor;
            border-radius: 20px;
            font-weight: 600;
            color: $activeColor;
        }

        .event-link {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 24px;
            line-height: 21px;
            padding: 0 24px;
            color: $primaryColor;
            text-decoration: none;
            cursor: pointer;

            .event-badge {
                position: absolute;
                color: $whiteColor;
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                background: $darkDangerColor;
                border-radius: 50rem;
                transform: translate(-50%, -50%);
                top: 3px;
                left: 65%;
                padding: 2px 5.25px;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
            }
        }

        .menu-btn {
            display: none;
            padding: 8px;
            margin: 0 15px 0 0;
            color: $primaryColor;
        }

        @media only screen and (max-width: 730px) {
            .menu-btn {
                display: flex;
            }
        }
    }
}
