.common-drawer {
    width: 400px;
    max-width: 400px;
    border: 0;
    padding: 32px !important;
    box-sizing: border-box;

    .header-title {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        color: $primaryColor;
        margin: 0;
    }

    .btn-close {
        position: absolute;
        box-shadow: none;
        outline: 0;
        top: 25px;
        right: 25px;
        cursor: pointer;
    }
}
