.sec-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
}

.topDropDown-wrapper {
    button {
        position: relative;
        background-color: #ffffff;
        border-radius: 20px !important;
        border: 1px solid #ccc;
        color: #7a7a7a !important;
        padding: 4px 170px 4px 15px;
        font-size: 14px !important;
        text-transform: none !important;
        font-weight: 400 !important;
        min-width: 300px;
        margin: 0 !important;
        text-align: left;
        svg {
            font-size: 18px;
            position: absolute;
            top: 7px;
            right: 10px;
        }
    }
}
.topDropDwn_head {
    font-weight: 600;
    color: $primaryColor;
    font-size: 14px;
    margin: 0 0 5px 8px;
    line-height: 18.62px;
}
.DropDownInput-wrapper {
    margin: 5px;
    width: 100%;

    .MuiTextField-root {
        width: 100%;
    }

    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
        border: 1px solid #ccc !important;
        color: #7a7a7a !important;
    }

    .css-1k23hlb-MuiButtonBase-root-MuiButton-root:hover {
        border: 1px solid #ccc !important;
        color: #7a7a7a !important;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 30px !important;
        font-size: 14px !important;
    }
    input {
        padding: 4px 14px !important;
        height: 25px;
    }
}
.DropDownOuter-wrapper {
    width: 300px;
    padding: 10px 14px 0 14px;
}

.DropDownBtn-wrapper {
    button {
        background: #ebeff8 !important;
        color: #21315b !important;
        border-radius: 20px !important;
        padding: 2px 5px !important;
        font-size: 14px !important;
        min-width: 126px !important;
        margin: 0 5px;
        text-transform: none !important;
    }
}
.css-1k23hlb-MuiButtonBase-root-MuiButton-root:hover {
    background: #ebeff8 !important;
    border: 1px solid #ebeff8 !important;
    border-radius: 20px !important;
    color: #21315b !important;
    padding: 2px 5px !important;
    font-size: 14px !important;
    min-width: 126px !important;
}

.headerDroplineSpace {
    margin: 5px 0 !important;
}

.dropDwnBottom-wrapper {
    display: flex;
    border-top: 1px solid #e0e0e0;
    padding: 10px 20px 0 20px;
    margin: 10px 0 0 0;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .topDropDwn-Btn {
        padding: 0px 43px;
    }
}

.header-prefix-icon-height {
    height: 100% !important;
}