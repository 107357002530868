@import "../src/assets/sass/layout/all";
@import "../src/assets/sass/pages/all";
@import "../src/assets/sass/abstracts/all";
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@900&display=swap");

* {
    font-family: "Poppins", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    position: relative;
    background-color: $primaryBgColor !important;
    margin: 0;
    display: unset;
    box-sizing: border-box;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    color: #fff !important;
}
