.map-wrapper {
    height: 400px;
}

.map-cluster-icon > div  {
    font-weight: bold !important;
    font-size: 16px !important;
    top:-5px !important;
    left:0px !important;
    color:white !important ;
}