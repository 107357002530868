.reporting-mail {
    margin: 22px 0 0 17px;
    .css-1iwtne7-MuiTypography-root-MuiLink-root {
        font-weight: 400;
    }
}
.reporting-btn {
    button {
        padding: 0 10px !important;
        min-width: 196px;
    }
}
.reportingHeader-wrapper {
    .reportingHeader-left {
        justify-content: flex-start;
    }
    .reportingHeader-right {
        justify-content: flex-end;
    }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: $activeColor !important;
    font-weight: 600;
}

.cus-tabs {
    margin-top: 30px;

    .tab-header.Mui-selected {
        background: $whiteColor;
        color: $activeColor;
        border-radius: 6px 6px 0px 0px;
        border-top: 2px solid $activeColor;
    }

    .tab-header {
        text-transform: none !important;
        background: #ededed;
        border-radius: 6px 6px 0px 0px;
        color: #b8b8b8;

        &:first-child {
            margin-right: 10px;
        }
    }
}

.tab-reporting {
    .filtersec-wrapper {
        margin-top: 0;
        margin-bottom: 10px;
    }
}
