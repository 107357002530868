.search-input-wrapper {
    position: relative;

    .search-icon {
        position: absolute;
        top: 6px;
        left: 11px;
        font-size: 20px;
        color: $secondaryColor;
    }

    .search-input {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border: 1px solid $borderColor;
        box-sizing: border-box;
        border-radius: 20px;
        padding-left: 40px;
        height: 33px;
        font-size: 14px;
        line-height: 21px;
        color: $secondaryColor;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}
