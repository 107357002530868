.section-heading {
    display: flex;
    align-items: center;

    .icon-div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 43px;
        height: 43px;
        padding: 10px;
        background: $iconBgColor;
        border-radius: 50%;
        margin-right: 17px;

        .icon {
            font-size: 24px;
            color: $primaryColor;
        }
    }

    .label {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        color: $primaryColor;
    }
}

.report-heading {
    display:flex;
    align-items: center;
    flex-direction: column;

    .main-header {
        font-weight: 600;
        font-size: 28px;
        line-height: 39px;
        color: $primaryColor;
        margin-bottom: 10px;
    }

    .sub-header {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $primaryColor;
    }
}
