.custom-container {
    position: relative;

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #ffffff;
    }

    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgb(150, 149, 149);
    }
}

.content {
    position: relative;
    height: 100%;
    width: calc(100% - 65px);
    left: 65px;
    transition: all 0.5s ease;
    padding: 25px;
    box-sizing: border-box;
}

.color-yellow {
    color: $yellowColor;
}

.color-danger {
    color: $dangerColor;
}

.color-darkDanger {
    color: $darkDangerColor;
}

.color-active {
    color: $activeColor;
}

.color-primary {
    color: $primaryColor;
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.fwBold {
    font-weight: 900;
}

.textDNone {
    a {
        text-decoration: none !important;
        color: #21315b !important;
    }
}

.textOverFlow {
    white-space: nowrap;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
        text-decoration: none !important;
        color: #21315b !important;
    }
}

.fpurpleSideNav {
    color: $activeColor !important;
}

.MuiTreeItem-content:hover {
    color: $activeColor !important;
}

a {
    color: #6b439d !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
}

.close-btn {
    position: absolute !important;
    width: 35px;
    height: 35px;
    top: 3px;
    right: 5px;
}

.circleBarCenter {
    z-index: 1;
    position: absolute;
}

.imageZIndex {
    position: relative;
    z-index: 0;
}
