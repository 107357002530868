.chartHearder {
    font-size: 14px;
    color: $primaryColor;
    font-weight: 600;
}

.chartHearderLight {
    font-size: 14px;
    color: $primaryColor;
    font-weight: 400;
}

.rightChart-placeholder {
    min-height: 406px;
}

.filtersec-wrapper {
    display: flex;
    align-items: end;
    gap: 8px;
    margin-top: 15px;
}

.filterLbl {
    display: block;
    font-size: 14px;
    color: $primaryColor;
}

@media only screen and (max-width: 1024px) {
    .filterLbl {
        font-size: 13px !important;
    }
}

.datePickerWrapper {
    .MuiInputBase-root {
        border-radius: 20px;
        border: 1px solid $borderColor;
    }

    input {
        padding: 7px 8px 6px 16px;
        font-size: 14px;
        color: $secondaryColor;
    }

    fieldset {
        border: 0;
    }
}

.fullChartWrapper {
    margin-top: 40px !important;
}

.chart-wrapper-cus-height {
    height: calc(50% - 16px);
}

.chart-padding-0 {
    padding: 0 !important;
}

.chart-header-alignment {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.doughnut-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .chart-wrapper {
        position: absolute;
        width: 120px;
        height: 120px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.doughnut-outer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doughnut-wrapper-half-width {
    width: 50% !important;
    position: relative;
}

.doughnut-direction-icon {
    position: absolute;
    top: 0;
    left: 0;
}

.doughnut-warning {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
    color: $primaryColor;
}


.graph-card{
    border-radius: 30px;
    padding: 10px;
    height: 380px;
}

.graph-card-unavailable {
    border-radius: 30px;
    padding: 10px;
    height: 300px;
}

.graph-title {
    font-size: 16px;
    color: $primaryColor;
    font-weight: 600;
    line-height: 20px;
}
