.paper-wrapper {
    background: $whiteColor;
    border-radius: 10px;
    padding: 25px;
    margin-top: 16px;
    position: relative;
}

.data-card-wrapper {
    background: $whiteColor;
    border-radius: 10px;
    margin-top: 16px !important;
}

.tab-reporting .css-19kzrtu {
    padding: 0;
}
